import classNames from 'classnames';
import { useRef } from 'react';
import { InputNew, Button } from 'vgui';

const FileButton = ({ icon, title, accept = false, children, onSelect, disabled = false }) => {
	const fileRef = useRef();
	const Icon = icon || children;

	const isAndroid = /(android)/i.test(navigator.userAgent);

	const handleClick = (e) => {
		fileRef.current.focus();
		fileRef.current.value = null;
		if (!isAndroid) fileRef.current.accept = accept;
		fileRef.current.click();
	};

	const handleSelect = (e) => {
		let files = e.target.files;
		if (accept) files = Array.from(files).filter((file) => isAllowedType(file, accept));
		if (files) onSelect(files);
	};

	return (
		<Button
			className={classNames('gap-2 option', disabled && 'opacity-25')}
			onClick={disabled ? () => {} : accept ? handleClick : onSelect}
			size="small"
			variant="transparent"
			onMouseDown={(e) => e.preventDefault()}
		>
			<Icon className="w-4" />
			{title}
			{accept && <InputNew multiple ref={fileRef} onChange={handleSelect} className="hidden" type="file" />}
		</Button>
	);
};

export default FileButton;

const isAllowedType = (file, accept) => {
	if (accept.includes('/') && !!file.type.match(accept.replace('/', '.'))) return true;

	const arrName = file?.name.split('.');
	const arrTypes = accept.replaceAll(' ', '').split(',');
	const fileType = arrName[arrName?.length - 1];

	if (arrTypes.includes(fileType) || arrTypes.includes(`.${fileType}`)) return true;

	return false;
};
