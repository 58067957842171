import { plural } from 'helpers';

const isDeleted = (status) => {
	return [0, 2, 3, 5].includes(status);
};

const relativeGroups = (n) => {
	return n + ' ' + plural(n, 'группа', 'группы', 'групп');
};

const relativeFollows = (n) => {
	return n + ' ' + plural(n, 'подписка', 'подписки', 'подписок');
};

const relativeFollowers = (n) => {
	return n + ' ' + plural(n, 'подписчик', 'подписчика', 'подписчиков');
};

export { isDeleted, relativeGroups, relativeFollows, relativeFollowers };
