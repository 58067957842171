import { postStoreNew, commentsStore } from 'stores';
import { api } from 'services';

// ---------- POSTS ----------

export const deletePost = (id, action = 'delete') => {
	return postStoreNew
		.patch(id, { action })
		.then(() => postStoreNew.update(id, { deleted: true }))
		.catch((e) => Promise.reject(e));
};

export const savePost = (post) => {
	if (post?.id) return postStoreNew.patch(post?.id, post);
	return postStoreNew.post(post);
};

// ---------- ATTACHMENTS ----------

export const uploadAttachment = (file, params, callback) => {
	return api
		.upload('/v1/attachment', file, params, callback)
		.then(({ data: rawData }) => {
			const { _meta, ...data } = rawData;
			return Promise.resolve(data);
		})
		.catch((e) => Promise.reject(e));
};

export const createAttachment = (params) => {
	return api
		.post('/v1/attachment', params)
		.then(({ data: rawData }) => {
			const { _meta, ...data } = rawData;
			return Promise.resolve(data);
		})
		.catch((e) => Promise.reject(e));
};

export const deleteAttachment = (id) => api.delete(`/v1/attachment/${id}`);

export const loadAttachments = (id) => {
	return api
		.get(`v1/attachment?object_id=${id}`, { cache: false })
		.then(({ data: { _meta, ...data } }) => {
			return Promise.resolve(data);
		})
		.catch((e) => Promise.reject(e));
};

export const getAttachment = (id) => {
	return api
		.get(`/v1/attachment/${id}`, { cache: false })
		.then(({ data }) => {
			const { _meta, ...item } = data;
			return Promise.resolve(item);
		})
		.catch((e) => Promise.reject(e));
};

export const updateAttachment = (id, context) => api.patch(`/v1/attachment/${id}`, { context });

export const uploadImage = (file, params, callback) => {
	return api
		.upload(`/v1/image`, file, params, callback)
		.then(({ data }) => {
			delete data['_meta'];
			return Promise.resolve(data);
		})
		.catch((e) => Promise.reject(e));
};

// ---------- COMMENTS ----------

export const postComment = (dataSend) => {
	const key = dataSend?.query;

	return commentsStore.post(dataSend).then((data) => {
		commentsStore.merge([data]);

		if (dataSend?.sort === '-id') {
			commentsStore.prepend(key, [data?.id]);
		} else {
			commentsStore.append(key, [data?.id]);
		}

		const count = commentsStore.getMeta(key)?.totalCount || 0;
		const totalCount = count + 1;
		commentsStore.setMetaField(key, { totalCount });

		recalcCountCommentsInPost(data?.post?.id, 1);
	});
};

export const patchComment = (id, dataSend) => {
	return commentsStore.patch(id, dataSend).then(() => {
		if (dataSend?.action === 'delete') {
			const count = commentsStore.getMeta(dataSend?.query)?.totalCount || 0;
			const totalCount = count > 0 ? count - 1 : 0;
			commentsStore.setMetaField(dataSend?.query, { totalCount });
		}
	});
};

export const recalcCountCommentsInPost = (postId, value = 1) => {
	const counters = postStoreNew.entity(postId)?.counters;

	if (counters) {
		const count = counters?.post_comment || 0;
		postStoreNew.update(postId, { counters: { ...counters, post_comment: count + value >= 0 ? count + value : 0 } });
	}
};
