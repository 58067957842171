import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { API_URL, DEV, VERSION } from 'constants/Config';

class Api {
	token = null;
	deviceId = null;
	instance = null;

	constructor() {
		const instance = axios.create({
			baseURL: API_URL,
			crossDomain: true,
			withCredentials: true,
			headers: {
				'Content-Type': 'application/json',
				'X-App-Version': 'webapp-' + VERSION,
			},
			validateStatus: (status) => {
				return status >= 200 && status < 300;
			},
		});

		instance.interceptors.request.use(this.getConfig);

		setupCache(instance, {
			methods: ['get'],
		});

		this.instance = instance;
	}

	getConfig = (config) => {
		if (DEV) console.debug(`[api] ${config.method.toUpperCase()} ${config.url}`);

		if (this.token) config.headers['Authorization'] = 'Token ' + this.token;
		if (this.deviceId) config.headers['X-Device-Id'] = this.deviceId;
		return config;
	};

	request = (...args) => this.instance.request(...args);
	get = (...args) => this.instance.get(...args);
	head = (...args) => this.instance.head(...args);
	delete = (...args) => this.instance.delete(...args);
	post = (...args) => this.instance.post(...args);
	patch = (...args) => this.instance.patch(...args);

	upload = (url, file, params = {}, callback = () => {}) => {
		const config = {
			headers: { 'Content-Type': 'multipart/form-data' },
			onUploadProgress: (event) => callback(event),
		};

		params['file'] = file;
		const formData = new FormData();
		Object.keys(params).forEach((key) => formData.append(key, params[key]));

		return this.instance.post(url, formData, config);
	};

	hash = (str) =>
		`${this.deviceId}|${str}`.split('').reduce((a, b) => (((a << 5) - a + b.charCodeAt(0)) & 0xffff) | 0, 0);
}

export default new Api();
