import { useLayoutEffect, useState } from 'react';
import { restoreAuth } from 'actions/auth';
import { restoreLocation } from 'modules/Geo/actions';
import { useTheme } from 'modules/Theme';

export default function useBootstrap() {
	const [isBootstrapComplete, setBootstrapComplete] = useState(false);

	useLayoutEffect(() => {
		const bootstrapAsync = async () => {
			await restoreAuth();
			await restoreLocation();
			setBootstrapComplete(true);

			const elem = document.getElementById('loader');
			if (elem) elem.parentNode.removeChild(elem);
		};

		bootstrapAsync();
	}, []);

	useTheme();

	return isBootstrapComplete;
}
