import { normalize } from 'normalizr';
import { messageSchema, chatSchema } from 'schemas';
import { layoutStore, chatStore, authStore, messageStore, profileStoreNew } from 'stores';

export function updateMessage(data) {
	const chatId = Number(data?.chat_id);

	if (messageStore.entity(data?.id)) {
		messageStore.update(data?.id, { ...data, data });
	} else {
		const normalized = normalize(data, messageSchema);
		messageStore.merge(normalized?.entities?.message);
		messageStore.prepend(`chat_id=${chatId}`, [normalized?.result]);
	}

	if (chatStore.entity(chatId)) {
		chatStore.sort(`chat_${authStore?.user?.id}`, chatId);
	}
}

export function setCounterChat(value) {
	layoutStore.setCountChat(value);
}

export function addChat(chat) {
	chatStore.merge([chat]);
	chatStore.prepend(`chat_${authStore?.user?.id}`, [chat?.id]);
}

export function addMessage(message) {
	const normalized = normalize(message, messageSchema);
	const chatId = message?.chat_id;
	const chat = chatStore.entity(chatId);
	const keyChatList = `chat_${authStore?.user?.id}`;

	messageStore.prepend(`chat_id=${chatId}`, [normalized?.result]);
	messageStore.merge(normalized?.entities?.message);

	profileStoreNew.merge(normalized?.entities?.profile);

	if (chat) {
		chatStore.update(chatId, { last: normalized?.result });
		chatStore.sort(keyChatList, chatId);
	} else if (!chat && chatStore.has(keyChatList)) {
		chatStore.get(chatId).then((data) => {
			const normalized = normalize(data, chatSchema);
			chatStore.prepend(keyChatList, [chatId]);
			chatStore.merge(normalized?.entities?.chat);
		});
	}
}

export function setStatus(items) {
	items.forEach((item) => messageStore.update(item?.id, { status: item?.status }));
}

export function setCountersMessages(items) {
	for (let key in items) {
		const chatId = items[key]?.id;
		const item = chatStore.entity(chatId);

		if (item) {
			chatStore.update(chatId, { counters: { ...item?.counters, unread: items[key]?.new } });
		}
	}
}
