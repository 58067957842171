import Chatra from '@chatra/chatra';
import { useCallback, useLayoutEffect } from 'react';

export default function useChatra() {
	useLayoutEffect(() => {
		Chatra('init', {
			ID: 'fAsvxyutKrG6FAXCe',
			setup: {
				deferredLoading: true,
				customWidgetButton: 'hidden',
				startHidden: true,
			},
		});

		return () => {
			Chatra('hide');
		};
	}, []);

	const openChatra = useCallback(() => {
		setTimeout(() => {
			Chatra('show');
			Chatra('openChat', { focus: true });
		}, 1);
	}, []);

	return openChatra;
}
