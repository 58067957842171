import * as types from 'constants/Types';
import { schema } from 'normalizr';

// feed (modules: Activity, Profile)
export const activitySchema = new schema.Entity(types.ACTIVITIES, {
	data: {
		post: new schema.Entity(types.POSTS),
		vacancy: new schema.Entity(types.VACANCY),
		adv: new schema.Entity(types.ADV),
	},
});

// messenger (modules: Messenger)
export const messageSchema = new schema.Entity(types.MESSAGE, {
	sender: new schema.Entity(types.PROFILE),
});

export const chatSchema = new schema.Entity(types.CHAT, {
	last: messageSchema,
});
