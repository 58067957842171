import { useEffect, useRef, useMemo } from 'react';
import { ArrowUturnRightIcon, PencilIcon, XMarkIcon } from '@heroicons/react/24/solid';
import { CameraIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { useToaster } from 'hooks';
import { useAuth, useAuthModal } from 'modules/Auth/hooks';
import { RichEditor } from 'modules/Editor';
import EditorStore from 'stores/editor/EditorStore';
import { CommentModel } from 'stores/editor/Models';
import { Button, HStack, Stack } from 'vgui';
import { ALLOW_MIME_VIDEO } from 'constants/Config';
import { patchComment, postComment } from 'modules/Editor/actions';

const CommentEditContainer = (props) => {
	const {
		id,
		value,
		postId,
		query,
		attachments,
		disabled = false,
		edit: [editId, setEdit],
		reply: [replyId, setReplyId],
	} = props;

	const ref = useRef();
	const { isAuth } = useAuth();
	const { toastPromise } = useToaster();
	const showAuthModal = useAuthModal('reaction');

	const editor = useMemo(() => new EditorStore(new CommentModel(postId)), [postId]);

	useEffect(() => {
		if (editId) {
			editor.setItem({
				id: id,
				postId: postId,
				text: value,
				attachments: attachments,
			});
			return;
		} else {
			editor.clear();
		}

		return () => editor.clear();
	}, [editId, editor, id, value, postId, attachments]);

	useEffect(() => {
		if (replyId || editId) ref.current.focus();
	}, [replyId, editId]);

	const handleSendComment = (text, attachments) => {
		if (postId && (text || attachments?.length > 0)) {
			const data = {
				query,
				post_id: postId,
				attachments: attachments,
				parent_id: replyId || null,
				body: text.replace(/<img alt="(.*?)" .*?>/g, '$1').replace(/&nbsp;/g, ' '),
			};

			if (!id) {
				toastPromise(postComment(data), {
					loading: 'Отправка комментария...',
					success: () => {
						setReplyId(null);
						editor.clear();
						ref.current.focus();
						return 'Комментарий отправлен';
					},
					error: (data) => data?.message,
				});
			} else {
				toastPromise(patchComment(id, data), {
					loading: 'Обновление комментария...',
					success: () => {
						setEdit(false);
						return 'Комментарий обновлен';
					},
					error: (data) => data?.message,
				});
			}
		}
	};

	const cancelComment = () => {
		setReplyId(null);
		setEdit(null);
		editor.clear();
	};

	if (!isAuth) {
		return (
			<Stack className="items-center p-4">
				<Button onClick={showAuthModal} variant="secondary">
					Добавить комментарий
				</Button>
			</Stack>
		);
	}

	return (
		<Stack
			className={classNames(
				'gap-2',
				(replyId || editId) && '-mx-4 px-2 pt-2 pb-4 shadow-inner border-b border-dotted',
			)}
		>
			{(replyId || editId) && (
				<HStack className="justify-between ml-2">
					{replyId ? (
						<HStack className="gap-2">
							<ArrowUturnRightIcon className="w-4 h-4 text-muted" />
							<span className="font-medium text-muted text-sm">Ответ на комментарий</span>
						</HStack>
					) : (
						<HStack className="gap-2">
							<PencilIcon className="w-4 h-4 text-gray-500" />
							<span className="font-medium text-muted text-sm">Изменение комментария</span>
						</HStack>
					)}

					<Button className="!p-1" variant="transparent" round onClick={cancelComment}>
						<XMarkIcon className="w-4 h-4" />
					</Button>
				</HStack>
			)}
			<RichEditor
				inputRef={ref}
				editor={editor}
				disabled={disabled}
				maxCountAttachments={1}
				onSubmit={handleSendComment}
				onEnterPress={handleSendComment}
				configAttachments={configAttachments}
				className="w-full max-h-[10rem] max-w-[calc(100%-4rem)]"
				placeholder={editId ? '' : 'Мой ' + (replyId ? 'ответ...' : 'комментарий...')}
			/>
		</Stack>
	);
};

export default CommentEditContainer;

const configAttachments = [
	{
		title: 'Фото',
		icon: CameraIcon,
		accept: 'image/*',
	},
	{
		title: 'Видео',
		icon: VideoCameraIcon,
		accept: ALLOW_MIME_VIDEO,
	},
];
