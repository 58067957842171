import {
	LockClosedIcon,
	QuestionMarkCircleIcon,
	ExclamationTriangleIcon,
	UserCircleIcon,
} from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';

import { ErrorPretty } from '../components/ErrorPretty';
import { setTitle } from 'helpers/utils';
import { setToken } from 'actions/auth';

const headers = {
	401: 'Ошибка авторизации',
	403: 'Доступ запрещен',
	404: 'Страница не найдена',
	410: 'Данные недоступны',
	500: 'Ошибка сервера',
};

const icons = {
	401: <UserCircleIcon />,
	403: <LockClosedIcon />,
	404: <QuestionMarkCircleIcon />,
	500: <ExclamationTriangleIcon />,
};

const Error = ({ e, code, text }) => {
	const location = useLocation();

	let header = 'Возникла ошибка';
	let icon = icons[500];

	if (code) {
		header = headers[code];
		icon = icons[code];
		if (!text && code === 500) text = 'Мы уже в процессе её исправления';
	} else {
		const { message, status, name } = e.response?.data || e.response || e;
		header = headers[status] || name;
		text = message;
		icon = icons[status] || icon[500];
		code = status;

		if (status === 401) setToken(null);
	}

	setTitle(header);

	return <ErrorPretty icon={icon} code={code} title={header} info={location.pathname} description={text} />;
};

export default Error;
