import { DEFAULT_LOCATION } from 'constants/Config';
import { localStore } from 'stores/storage';
import { api, ReactAdFox } from 'services';
import { authStore, profileStoreNew } from 'stores';

export const getGeoIP = () => {
	return api
		.get('/v1/geo/ip')
		.then(({ data }) => {
			delete data['_meta'];
			return Promise.resolve(data);
		})
		.catch(() => {});
};

export const setLocation = (location, confirm = true) => {
	authStore.setLocation({ ...location, confirm });
	localStore.setItem('location', JSON.stringify({ ...location, confirm }));
	ReactAdFox.setCity(authStore?.location?.id);

	setTimeout(() => updateLocation(location), 1000);
};

const updateLocation = async (location) => {
	if (authStore?.user?.id && authStore?.user?.settings?.geo_id !== location?.id) {
		profileStoreNew.patch(authStore?.user?.id, { settings: { geo_id: location?.id } });
	}
};

export async function restoreLocation() {
	try {
		const locationLS = JSON.parse(localStore.getItem('location'));
		if (locationLS?.id) return setLocation(locationLS);
	} catch {}

	if (authStore?.user?.geo) return setLocation(authStore?.user?.geo);

	const location = await getGeoIP();
	return setLocation(location || DEFAULT_LOCATION, false);
}
