import { layoutStore } from 'stores';
import {
	addMessage,
	addChat,
	setStatus,
	setCounterChat,
	setCountersMessages,
	updateMessage,
} from 'modules/Messenger/actions/cent';
import { cent } from 'services';

export async function connectCent(user_id, jwt) {
	if (cent.isConnected()) return;
	cent.connect(jwt, user_id, handler);
}

export async function disconnectCent() {
	cent.disconnect();
}

const handler = (m) => {
	if (!m) return;

	const { action, message, items, counter, chat } = m.data;

	switch (action) {
		case 'new_chat':
			addChat(chat);
			break;

		case 'new_message':
			addMessage(message);
			break;

		case 'update_message':
			updateMessage(message);
			break;

		case 'message_status':
			setStatus(items);
			break;

		case 'counters':
			setCounterChat(counter);
			setCountersMessages(items);
			break;

		case 'notice_counter':
			layoutStore.setCountNotice(counter);
			break;

		default:
			break;
	}
};
