import * as Sentry from '@sentry/react';
import { getCookie, setCookie, uuid } from 'helpers';
import { api } from 'services';
import { authStore, layoutStore, profileStoreNew } from 'stores';
import { localStore } from 'stores/storage';

import { connectCent, disconnectCent } from './cent';

export async function restoreAuth() {
	await restoreDeviceId();

	let token = localStore.getItem('token');
	if (!token || token === 'undefined') {
		localStore.removeItem('token');
		return;
	}

	await setToken(token);
	await refreshToken();
	await getMe();
}

export async function getMe() {
	return api
		.get(`/v1/profile/me`, { cache: false })
		.then(({ data }) => {
			authStore.setUser(data);
			profileStoreNew.update(data?.id, data);
			layoutStore.setCountNotice(data.counters?.new_notices || 0);
			layoutStore.setCountChat(data.counters?.new_messages || 0);
			return Promise.resolve(data);
		})
		.catch((e) => {
			if (e.status === 401) setToken(null);
		});
}

export async function setToken(token) {
	api.token = token;
	if (token) {
		localStore.setItem('token', token);
	} else {
		localStore.removeItem('token');
		authStore.setUser(null);
		disconnectCent();
	}

	// console.debug('[token]', token);
}

export async function restoreDeviceId() {
	let deviceId = localStore.getItem('device_id');
	if (!deviceId) deviceId = getCookie('device_id');
	if (!deviceId) {
		deviceId = uuid();
		localStore.setItem('device_id', deviceId);
	}
	api.deviceId = deviceId;
	setCookie('device_id', deviceId, 365);
	Sentry.setTag('device.id', deviceId);

	// console.debug('[device]', deviceId);
}

export function refreshToken() {
	return api
		.get('/v1/auth/refresh')
		.then(({ data }) => {
			if (data?.token) setToken(data.token);
			if (data?.user_id && data?.jwt) connectCent(data.user_id, data.jwt);
		})
		.catch((e) => {
			if (e.status === 401) {
				setToken(null);
				return (window.location.href = '/auth');
			}
		});
}
