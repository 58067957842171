import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Button, Stack } from 'vgui';
import { useNavigate } from 'react-router-dom';

export const ErrorPretty = ({
	icon = <ExclamationTriangleIcon />,
	title,
	info,
	code,
	description,
	children,
	backText = 'Вернуться назад',
}) => {
	const navigate = useNavigate();

	const handleGoBack = () => {
		if (window.history?.length && window.history.length > 1) {
			navigate(-1);
		} else {
			navigate('/', { replace: true });
		}
	};

	return (
		<Stack className="h-screen -mt-16 items-center justify-center px-4 text-center">
			<span className="w-32 mb-4">{icon}</span>
			{title && <h1>{title}</h1>}
			{info && <div className="bg-gray-300 bg-opacity:20 px-4 py-2 mb-8 rounded-md text-muted">{info}</div>}
			{description && <div>{description}</div>}
			{children}

			<Button className="mt-8" onClick={handleGoBack}>
				{backText}
			</Button>
		</Stack>
	);
};

export default ErrorPretty;
