import useTheme from '../hooks/useTheme';
import { Pressable } from 'vgui';

import { LightBulbIcon, MoonIcon, SunIcon } from '@heroicons/react/24/outline';

const getTheme = (theme) => {
	if (theme === 'light') {
		return {
			icon: <SunIcon />,
			hint: 'Светлая',
		};
	}

	if (theme === 'dark') {
		return {
			icon: <MoonIcon />,
			hint: 'Тёмная',
		};
	}

	return {
		icon: <LightBulbIcon />,
		hint: 'Системная',
	};
};

export const ThemeButton = () => {
	const { theme, switchTheme } = useTheme();

	return (
		<Pressable className="w-6 hover:text-primary" title={getTheme(theme)} onClick={switchTheme}>
			{getTheme(theme).icon}
		</Pressable>
	);
};
