import { useLayoutOptions } from 'modules/Layout';
import Error from '../components/Error';

const ErrorScreen = (props) => {
	useLayoutOptions({
		title: 'Ошибка',
		hideAppBar: true,
		hideAside: true,
		hideNavigator: true,
	});

	return <Error {...props} />;
};

export default ErrorScreen;
