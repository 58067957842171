import Root from 'modules/Layout/components/Root';
import { lazy } from 'react';
import { redirect } from 'react-router-dom';
import { NotFound, ErrorBoundary } from 'modules/Error';
import { localStore } from 'stores/storage';

import modalLoader from './modal';

const Geo = lazy(() => import('modules/Geo/screens/GeoScreen'));
const Search = lazy(() => import('modules/Search/screens/SearchScreen.tsx'));
const TestScreen = lazy(() => import('modules/Test/screens/TestScreen'));
const FeedCity = lazy(() => import('modules/Feed/screens/FeedCity'));
const FeedMocked = lazy(() => import('modules/Feed/screens/FeedMocked'));
const AuthScreen = lazy(() => import('modules/Auth/screens/AuthScreen'));

const MobileApp = lazy(() => import('modules/Apps/screens/MobileApp'));
const Apps = lazy(() => import('modules/Apps/screens/AppsList'));
const Bookmarks = lazy(() => import('modules/Bookmarks/screens/BookmarksScreen'));
const Welcome = lazy(() => import('modules/Welcome/screens/WelcomeScreen'));
const InviteRequest = lazy(() => import('modules/Invite/screens/InviteRequestScreen'));

const GroupRouter = lazy(() => import('modules/Group/GroupRouter'));
const GroupListRouter = lazy(() => import('modules/Group/GroupListRouter'));
const MessengerRouter = lazy(() => import('modules/Messenger/MessengerRouter'));
const ProfileRouter = lazy(() => import('modules/Profile/ProfileRouter'));

const rootLoader = () => {
	try {
		const isAuth = localStore.getItem('token');
		if (isAuth) return redirect('/feed');

		const location = JSON.parse(localStore.getItem('location'));
		if (location) return redirect('/' + location.alias);

		return redirect('/auth');
	} catch {}

	return null;
};

export const routes = [
	{
		path: '/',
		element: <Root />,
		errorElement: <ErrorBoundary />,
		loader: modalLoader,
		children: [
			{
				path: '',
				exact: true,
				loader: rootLoader,
			},
			{
				path: '/auth',
				element: <AuthScreen />,
			},
			{
				path: '/user/login',
				loader: () => redirect('/auth'),
			},
			{
				path: '/news',
				loader: () => redirect('/novosti'),
			},
			{
				path: '/feed',
				element: <FeedCity alias="feed" />,
			},
			{
				path: '/df',
				element: <FeedMocked />,
			},
			{
				path: '/ui',
				element: <TestScreen />,
			},
			{
				path: '/geo',
				element: <Geo />,
			},
			{
				path: '/im/*',
				element: <MessengerRouter />,
			},
			{
				path: '/legal/*',
				lazy: () => import('modules/Legal'),
			},
			{
				path: '/group/*',
				element: <GroupRouter />,
			},
			{
				path: 'groups/*',
				element: <GroupListRouter />,
			},
			{
				path: '/search',
				element: <Search />,
			},
			{
				path: '/install',
				element: <MobileApp />,
			},
			{
				path: '/apps',
				element: <Apps />,
			},
			{
				path: '/news/:id',
				loader: ({ params }) => redirect('/post' + params.id),
			},
			{
				path: '/profile/*',
				element: <ProfileRouter />,
			},
			{
				path: '/welcome',
				element: <Welcome />,
			},
			{
				path: '/invite/:token',
				element: <InviteRequest />,
			},
			{
				path: '/bookmarks',
				element: <Bookmarks />,
			},
			{
				path: '/bookmarks/:alias',
				element: <Bookmarks />,
			},
			{
				path: '/:alias/*',
				lazy: () => import('./slug'),
			},
		],
	},
	{
		path: '*',
		element: <NotFound />,
	},
];
