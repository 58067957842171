import plural from './plural';

const months = [
	'Январь',
	'Февраль',
	'Март',
	'Апрель',
	'Май',
	'Июнь',
	'Июль',
	'Август',
	'Сентябрь',
	'Октябрь',
	'Ноябрь',
	'Декабрь',
];

const monthsr = [
	'января',
	'февраля',
	'марта',
	'апреля',
	'мая',
	'июня',
	'июля',
	'августа',
	'сентября',
	'октября',
	'ноября',
	'декабря',
];

const monthss = ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'];
const days = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];
const dayss = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб'];

export function dateParse(date) {
	return new Date(date);
}

export function dateFormat(date, format) {
	if (typeof 'date' !== 'object') date = dateParse(date);

	var string = '',
		mo = date.getMonth(), // month (0-11)
		m1 = mo + 1, // month (1-12)
		dow = date.getDay(), // day of week (0-6)
		d = date.getDate(), // day of the month (1-31)
		y = date.getFullYear(), // 1999 or 2003
		h = date.getHours(), // hour (0-23)
		mi = date.getMinutes(), // minute (0-59)
		s = date.getSeconds(); // seconds (0-59)

	const z = (v) => {
		return v < 10 ? '0' + v : v;
	};

	for (var i = 0, len = format.length; i < len; i++) {
		switch (format[i]) {
			case 'j':
				string += d;
				break;

			case 'd':
				string += z(d);
				break;

			case 'l':
				string += days[dow];
				break;

			case 'w':
				string += dow;
				break;

			case 'D':
				string += dayss[dow];
				break;

			case 'm':
				string += z(m1);
				break;

			case 'n':
				string += m1;
				break;

			case 'F':
				string += months[mo];
				break;

			case 'R':
				string += monthsr[mo];
				break;

			case 'M':
				string += monthss[mo];
				break;

			case 'Y':
				string += y;
				break;

			case 'y':
				string += y.toString().slice(-2);
				break;

			case 'H':
				string += z(h);
				break;

			case 'g':
				var hour = h === 0 ? 12 : h;
				string += hour > 12 ? hour - 12 : hour;
				break;

			case 'h':
				hour = h === 0 ? 12 : h;
				hour = hour > 12 ? hour - 12 : hour;
				string += z(hour);
				break;

			case 'a':
				string += h < 12 ? 'am' : 'pm';
				break;

			case 'i':
				string += z(mi);
				break;

			case 's':
				string += z(s);
				break;

			case 'c':
				string += date.toISOString();
				break;

			default:
				string += format[i];
		}
	}

	return string;
}

export function equalDatetime(dt1, dt2 = '') {
	return new Date(dt1).getTime() === new Date(dt2).getTime();
}

export function equalDate(dt1, dt2 = '') {
	return new Date(dt1).toDateString() === new Date(dt2).toDateString();
}

export function equalYear(dt1, dt2 = '') {
	return new Date(dt1).getFullYear() === new Date(dt2).getFullYear();
}

export function dateISO(value = null) {
	return dateFormat(value || new Date(), 'c');
}

function relativeTime(n) {
	if (n < 60) {
		return n + ' cек';
	}

	if (n < 3600) {
		n = parseInt(n / 60);
		return n + ' мин';
	}

	n = parseInt(n / 3600);
	return n === 1 ? 'час' : n + ' ' + plural(n, 'час', 'часа', 'часов');
}

export function datePretty(value) {
	const dt = new Date(value);

	if (dt.getFullYear() < 2000) return 'очень давно';

	const d = new Date();
	const td = new Date(d.toDateString());

	if (dt >= td) {
		const n = parseInt((d.getTime() - dt.getTime()) / 1000);
		if (n > -5) return n < 5 ? 'только что' : relativeTime(n) + ' назад';
	}

	const yd = new Date(d.toDateString()).setDate(td.getDate() - 1);

	const time = ', ' + timeShort(dt);

	if (dt >= yd && dt < td) return 'вчера' + time;

	const diff = Math.ceil(Math.abs(d - dt) / (1000 * 60 * 60 * 24));
	if (diff < 335) return dateFormat(dt, 'j M') + time;

	return dateFormat(dt, 'j.m.y') + time;
}

export function dateShort(value) {
	return dateFormat(value, 'j R' + (!equalYear(value) ? ' Y' : ''));
}

export function timeShort(value) {
	return dateFormat(value, 'H:i');
}

export function timeFull(value) {
	return dateFormat(value, 'H:i:s');
}

export function datetimeShort(value) {
	if (equalDate(value)) return timeShort(value);
	return dateFormat(value, 'd.m.y');
}

export function dateFull(value) {
	return dateFormat(value, 'j R Y');
}

export function dateTimeFull(value) {
	const dt = value ? new Date(value) : new Date();
	const time = ', ' + timeShort;

	const d = new Date();
	const td = new Date(d.toDateString());
	const yd = new Date(d.toDateString()).setDate(td.getDate() - 1);

	if (dt > td) return 'сегодня' + time;
	if (dt >= yd && dt < td) return 'вчера' + time;

	return dateFull + time;
}

export function expired(value, timeout) {
	return value.getTime() < new Date().getTime() - timeout;
}

export function coming(value) {
	return new Date(value).getTime() - new Date().getTime() > 10000;
}

export function lastSeen(value, gender = 0) {
	if (value === null) return 'не в сети';

	const dt = new Date(value);
	const d = new Date();

	const n = parseInt((d.getTime() - dt.getTime()) / 1000);
	const g = gender === 2 ? 'была' : gender === 1 ? 'был' : 'был(а)';
	return n < 60 ? 'в сети' : g + ' ' + datePretty(value);
}

export function dateSeparator(currentItemDate, prevItemDate) {
	const countDaysBetween = 335;
	const dc = new Date(currentItemDate);
	const dp = prevItemDate ? new Date(prevItemDate) : new Date();

	const diffDaysBetweenCurrentDate = Math.round((new Date() - dc) / (24 * 3600 * 1000));
	const diffDaysBetweenItems = Math.round((dc - dp) / (24 * 3600 * 1000));

	if (prevItemDate && diffDaysBetweenItems === 0) {
		return null;
	}

	if (diffDaysBetweenCurrentDate >= countDaysBetween) {
		return `${dc.getDate()} ${monthsr[dc.getMonth()]} ${dc.getFullYear()}`;
	}

	if (diffDaysBetweenCurrentDate >= 0 && diffDaysBetweenCurrentDate < countDaysBetween) {
		return `${dc.getDate()} ${monthsr[dc.getMonth()]}`;
	}

	if (prevItemDate && diffDaysBetweenCurrentDate >= countDaysBetween) {
		return `${dc.getDate()} ${monthsr[dc.getMonth()]} ${dc.getFullYear()}`;
	}

	return `${dc.getDate()} ${monthsr[dc.getMonth()]} ${dc.getFullYear()}`;
}
