import { getCookie, setCookie } from 'helpers/cookie';
import { useEffect, useState } from 'react';
import { Button, Card, Link } from 'vgui';

const CookieNote = () => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		if (!getCookie('cookies_policy')) setShow(true);
	}, []);

	if (!show) return null;

	const handleClick = () => {
		setCookie('cookies_policy', true, 365);
		setShow(false);
	};

	return (
		<Card className="fixed z-[1000] bg-[#fdfae8] bg-opacity-90 p-4 rounded border shadow-lg min-w-[300px] max-w-[96%] md:max-w-[70%] bottom-0 mb-2 md:mb-5 w-full left-[2%] right-[2%] md:left-[20%] md:right-[20%]">
			<div className="flex flex-col md:flex-row items-center md:justify-between gap-2">
				<div className="text-sm text-center">
					<span>Продолжая использовать сайт, Вы </span>
					<Link className={'text-primary underline hover:cursor-pointer hover:no-underline'} to="/legal/privacy">
						даёте&nbsp;согласие
					</Link>
					<span> на работу с файлами cookie.</span>
				</div>
				<Button size="small" className="w-1/2 sm:w-24 mt-2 sm:mt-0" onClick={handleClick}>
					ОК
				</Button>
			</div>
		</Card>
	);
};

export default CookieNote;
