import { toQueryString } from 'helpers';
import { makeObservable, action, observable, toJS } from 'mobx';
import { normalize, schema } from 'normalizr';
import { api } from 'services';

import DataStore from './dataStore';

const stringify = (val) => (val ? (val instanceof Object ? `?${toQueryString(val)}` : `?${val}`) : '');

export default class ApiStore extends DataStore {
	constructor(params) {
		super();
		this.wipe();
		this.baseUrl = params?.baseUrl;
		this.model = params?.model || 'entity';
		this.schema = params?.schema || new schema.Entity(this.model);

		makeObservable(this);
	}

	// ---------- GENERAL ----------
	wipe = action(() => {
		this.meta = observable.map({});
		this.state = observable.map({});
		this.errors = observable.map({});
	});

	// ---------- ACTIONS ----------
	request = async ({ key, ...props }) => {
		if (!key) key = props.url;

		if (this.isLoading(key)) return Promise.reject();
		this.setLoading(key);

		return api.request(props).finally(() => this.setLoading(key, false));
	};

	fetch = async (key, params = {}) => {
		const meta = this.getMeta(key);
		const { query, clear, normalization = true } = params;

		if (!clear && !meta?.hasMore) return;

		const url = (!clear && meta?.nextHref) || this.baseUrl + stringify(query);

		return this.request({ key, url, method: 'get', cache: params?.cache || !clear })
			.then(({ data }) => {
				if (clear) this.clear(key);

				if (normalization) {
					const normalized = normalize(data, { items: [this.schema] });
					this.merge(normalized?.entities[this.model]);
					this.append(key, normalized?.result?.items);
				}

				this.setMeta(key, data);

				return Promise.resolve(data);
			})
			.catch((e) => {
				this.setError(key, e);
				return Promise.reject(e);
			});
	};

	get = async (id, params = {}) => {
		const { query, cache = false } = params;
		const url = `${this.baseUrl}/${id}` + stringify(query);

		return this.request({ url, method: 'get', cache }).then((result) => {
			if (result?.data) {
				const { data } = result;
				delete data['_meta'];
				const normalized = normalize(data, this.schema);
				this.merge(normalized?.entities[this.model]);
				return Promise.resolve(data);
			}
		});
	};

	patch = async (id, data) => {
		return this.request({ url: `${this.baseUrl}/${id}`, method: 'patch', data }).then(({ data }) => {
			delete data['_meta'];
			this.update(data?.id, data);
			return Promise.resolve(data);
		});
	};

	delete = async (id) => this.request({ url: `${this.baseUrl}/${id}`, method: 'delete' });

	post = async (data) => {
		return this.request({ url: this.baseUrl, method: 'post', data }).then(({ data }) => {
			delete data['_meta'];
			return Promise.resolve(data);
		});
	};

	// ---------- META ----------

	setMeta = action((key, { _meta, _links }) => {
		const ts = toJS(this.getMeta(key)?.ts);

		this.meta.set(key.toString(), {
			firstHref: _links?.first?.href,
			nextHref: _links?.next?.href,
			hasMore: !!_links?.next?.href,
			perPage: parseInt(_meta?.perPage),
			pageCount: parseInt(_meta?.pageCount),
			totalCount: parseInt(_meta?.totalCount),
			currentPage: parseInt(_meta?.currentPage),
			countUnread: parseInt(_meta?.countUnread),
			...(_meta?.currentPage < 2 ? { ts: new Date().getTime() } : { ts }),
		});
	});

	setMetaField = action((key, values) => {
		this.meta.set(key.toString(), { ...toJS(this.meta.get(key.toString())), ...values });
	});

	getMeta = (key) => this.meta.get(key.toString());

	hasMore = (key) => this.meta.get(key.toString())?.hasMore;

	outdated = (key, ttl = 600) => {
		const ts = this.getMeta(key)?.ts;
		return ts ? Math.floor(new Date().getTime() - ts) > ttl * 1000 : false;
	};

	// ---------- REQUESTS ----------
	setLoading = action((key, value = true) => this.state.set(key.toString(), value));

	isLoading = (key) => !!this.state.get(key.toString());

	error = (key) => this.errors.get(key.toString());

	setError = action((key, e) => this.errors.set(key.toString(), e));
}
