import { EyeIcon, ChatBubbleOvalLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import ReactionWidget from 'components/Reaction/ReactionWidget';
import { coming, datetimeShort } from 'helpers/date';
import { shortNum } from 'helpers/shortNum';
import { relative } from 'helpers/utils';
import { useIntersection } from 'hooks/intersectionObserver';
import { ComplaintTypes, useComplaintModal } from 'modules/Complaint';
import SubscribeWidget from 'modules/Group/components/SubscribeWidget';
import ShareButton from 'modules/Share/ShareButton';
import { memo, useRef, lazy } from 'react';
import { useModal } from 'react-modal-hook';
import { useNavigate } from 'react-router-dom';
import { Card, Stack, HStack, DatePretty, Button, Pressable, Link } from 'vgui';
import DropdownWidget from 'vgui/components/elements/DropdownWidget';

import PostBody from './PostBody';
import PostHeader from './PostHeader';
import RepostHeader from './RepostHeader';

const PostStatisticsModal = lazy(() => import('./PostStatisticsModal'));
const LegalModal = lazy(() => import('modules/Post/modals/LegalModal'));

const AdvTypes = {
	1: 'organic',
	2: 'paid',
	3: 'promo',
	5: 'viral',
};

const FooterDraft = ({ id, author, dt_update, permissions }) => {
	const navigate = useNavigate();

	return (
		<HStack className="justify-between w-full mt-2 gap-2">
			<HStack className="gap-2">
				<span className="text-xs text-muted">Обновлено: {datetimeShort(dt_update)}</span>
			</HStack>
			{permissions?.can_post_edit && (
				<Button size="small" variant="secondary" onClick={() => navigate('?w=post' + id + '&mode=edit')}>
					Редактировать
				</Button>
			)}
		</HStack>
	);
};

const Footer = ({ permissions, reaction, id, params, url, counters, isAuth, onEvent, onOpenPost }) => (
	<HStack className="justify-between w-full">
		<HStack className="gap-2">
			<ReactionWidget
				id={id}
				values={reaction}
				isAuth={isAuth}
				onSelect={(value) => {
					onEvent('post_reaction', { ...{ value } });
				}}
				disabled={!permissions?.can_post_view}
			/>
			{!params.no_comments && (
				<Button
					round
					size="small"
					variant="secondary"
					onClick={onOpenPost}
					title="Комментарии"
					disabled={!permissions?.can_post_view}
				>
					<ChatBubbleOvalLeftIcon className="w-4" />
					{counters['post_comment'] || 0}
				</Button>
			)}
			<ShareButton url={url} disabled={!permissions?.can_post_view} />
		</HStack>
		<HStack className="items-center gap-1">
			<EyeIcon className="w-4" />
			<span className="text-xs">{shortNum(counters['post_reach'])}</span>
		</HStack>
	</HStack>
);

const HeaderButtons = (props) => {
	const {
		id,
		permissions,
		onDelete,
		onDrain,
		params,
		onBookmark,
		onPin,
		activity,
		isAuth,
		group,
		decoration,
		type,
		legal,
		onPressSubscribe,
	} = props;

	const showComplaintModal = useComplaintModal({ id, type: ComplaintTypes.POST });

	const [showLegalModal, hideLegalModal] = useModal(() => (
		<LegalModal onHide={hideLegalModal} token={legal.mark.erir.token} />
	));

	const [showStatModal, hideStatModal] = useModal(() => <PostStatisticsModal onClose={hideStatModal} {...props} />);

	const navigate = useNavigate();

	return (
		<HStack className="gap-x-1 shrink-0 self-start">
			{group && <SubscribeWidget id={group.id} access={decoration.header.access} onClick={onPressSubscribe} />}
			{!activity && (
				<DropdownWidget
					options={[
						isAuth &&
							onBookmark && {
								value: params?.bookmark ? 'Убрать из закладок' : 'Добавить в закладки',
								onClick: () => onBookmark(id, !params?.bookmark),
							},
						permissions?.can_post_admin &&
							group &&
							onPin && {
								value: params?.pinned ? 'Открепить' : 'Закрепить',
								onClick: () => onPin(id, !params?.pinned),
							},
						permissions?.can_post_drain && {
							value: 'Скрыть из ленты',
							onClick: () => onDrain(props),
						},
						{
							value: 'Пожаловаться',
							onClick: showComplaintModal,
						},
						permissions?.can_post_edit &&
							type === 'post' && {
								value: 'Редактировать',
								onClick: () => navigate('?w=post' + id + '&mode=edit'),
							},
						permissions?.can_post_delete &&
							onDelete && {
								value: 'Удалить пост',
								onClick: () => onDelete(id),
							},
						permissions?.can_post_stat && {
							value: 'Статистика',
							onClick: showStatModal,
						},
						legal?.mark?.erir?.token && {
							value: 'О рекламодателе',
							onClick: showLegalModal,
						},
						legal?.mark?.erir?.token && {
							value: 'ERID: ' + legal.mark.erir.token,
							onClick: showComplaintModal,
						},
					]}
				/>
			)}
		</HStack>
	);
};

const PostItem = (props) => {
	const {
		id,
		group,
		url,
		legal,
		dt_publish,
		decoration,
		params,
		repost = false,
		isAuth,
		onHide,
		onRestore,
		deleted = false,
		hide = false,
		drain = false,
		onEvent = () => {},
		children,
		f_status,
		status,
	} = props;

	const postRef = useRef();

	const navigate = useNavigate();

	useIntersection({
		ref: postRef,
		type: 'post',
		callback: () => onEvent('post_reach'),
		params: {
			rootMargin: '30px 0px',
			threshold: 0.5,
			trackVisibility: true,
			delay: 500,
		},
	});

	if (hide) return null;

	if (repost)
		return (
			<Stack className="gap-3">
				{decoration && <RepostHeader {...{ decoration, url, dt_publish }} />}
				<div className="border-l-[5px] pl-[11px] -ml-[16px]">
					<PostBody {...props} />
				</div>
			</Stack>
		);

	if (drain)
		return (
			<Card className="bg-gray-200">
				<Card.Body className="flex flex-row justify-center items-center gap-1 p-4 leading-10">
					<span>Посты от {group?.id ? 'группы' : 'пользователя'} будут скрыты из ленты.</span>
					<Button
						round
						size="rect"
						variant="danger-transparent"
						className="absolute right-4 !bg-white"
						onClick={() => onHide(id)}
					>
						<XMarkIcon className="text-red-500 w-5 h-5" />
					</Button>
				</Card.Body>
			</Card>
		);

	if (deleted)
		return (
			<Card className="bg-gray-200">
				<Card.Body className="flex flex-row justify-center items-center gap-1 p-4 leading-10">
					<span>Запись удалена.</span>
					<Pressable onClick={() => onRestore(id)} className="link text-base">
						Восстановить?
					</Pressable>
					<Button
						round
						size="rect"
						variant="danger-transparent"
						className="absolute right-4 !bg-white"
						onClick={() => onHide(id)}
					>
						<XMarkIcon className="text-red-500 w-5 h-5" />
					</Button>
				</Card.Body>
			</Card>
		);

	const handleOpenPost = (e) => {
		e.preventDefault();
		onEvent('post_open');
		navigate(isAuth ? '?w=post' + id : relative(url));
	};

	return (
		<Card ref={postRef} data-id={id} data-type={AdvTypes[params?.adv || 1]}>
			{children}
			<Card.Header>
				<HStack className="justify-between w-full">
					<PostHeader
						{...decoration.header}
						status={f_status === 1 ? (coming(dt_publish) ? 'Отложен' : '') : status}
						pinned={params?.pinned}
						anon={params?.no_author}
					>
						{legal?.mark?.notice ? (
							<span className="truncate" title={legal?.mark?.service_alias}>
								{legal.mark.notice}
							</span>
						) : (
							<Link to={url} onClick={handleOpenPost}>
								<DatePretty value={dt_publish} />
							</Link>
						)}
					</PostHeader>
					<HeaderButtons {...props} />
				</HStack>
			</Card.Header>
			<Card.Body>
				<PostBody {...props} onOpenPost={handleOpenPost} />
			</Card.Body>
			<Card.Footer>
				{params?.draft ? <FooterDraft {...props} /> : <Footer {...props} onOpenPost={handleOpenPost} />}
			</Card.Footer>
		</Card>
	);
};

export default memo(
	PostItem,
	(a, b) =>
		a.counters === b.counters &&
		a.deleted === b.deleted &&
		a.hide === b.hide &&
		a.dt_index === b.dt_index &&
		a.decoration === b.decoration,
);
