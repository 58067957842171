import ApiStore from './apiStore';
import authStore from './authStore';
import layoutStore from './layoutStore';

// feed (modules: Activity)
const feedStore = new ApiStore({ baseUrl: '/v1/feed' });

// messenger (modules: Messenger)
const chatStore = new ApiStore({ baseUrl: '/v1/chat' });
const messageStore = new ApiStore({ baseUrl: '/v1/message' });

// profiles (modules: Messenger, Profile)
const profileStoreNew = new ApiStore({ baseUrl: '/v1/profile' });

// groups (modules: Activity, Group, Post)
const groupStoreNew = new ApiStore({ baseUrl: '/v1/group' });

// posts (modules: Reactions, Activity, Group, Post, Profile)
const postStoreNew = new ApiStore({ baseUrl: '/v1/post' });

// activity (modules: Profile)
const activityStoreNew = new ApiStore({ baseUrl: '/v1/activity' });

// comments (modules: Comment)
const commentsStore = new ApiStore({ baseUrl: '/v1/comment' });

export const wipe = () => authStore.wipe();

export {
	ApiStore,
	activityStoreNew,
	authStore,
	chatStore,
	commentsStore,
	feedStore,
	groupStoreNew,
	layoutStore,
	messageStore,
	postStoreNew,
	profileStoreNew,
};
