import { useEffect, useState } from 'react';
import { ArrowPathIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { plural } from 'helpers';
import scrollToElement from 'helpers/scrollToElement';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import useAuth from 'modules/Auth/hooks/useAuth';
import { useLocation } from 'react-router-dom';
import { Stack, HStack, Button, ItemListNew } from 'vgui';
import { commentsStore, postStoreNew } from 'stores';
import { recalcCountCommentsInPost } from 'modules/Editor/actions';
import { useToaster } from 'hooks';
import { api } from 'services';

import '../style.css';

import CommentItem from '../components/CommentItem';
import CommentEditContainer from './CommentEditContainer';

const CommentListContainer = ({ id }) => {
	const query = `post_id=${id}&limit=500&sort=id`;

	const location = useLocation();
	const { isAuth, user } = useAuth();
	const { toastPromise } = useToaster();
	const [error, setError] = useState(null);
	const [editId, setEdit] = useState(null);
	const [replyId, setReplyId] = useState(null);

	const ids = commentsStore.ids(query);
	const data = commentsStore.entities(query);
	const exists = commentsStore.has(query) && !commentsStore.outdated(query, 300);
	const totalCount = commentsStore.getMeta(query)?.totalCount;
	const loading = commentsStore.isLoading(query);
	const loaded = loading !== undefined;

	const post = postStoreNew.entity(id);

	useEffect(() => {
		if (!exists) {
			commentsStore
				.fetch(query, { query, clear: true })
				.then(() => {})
				.catch((e) => setError(e?.response?.data || e?.response));
		}
	}, [query, exists]);

	useEffect(() => {
		if (ids && location?.hash) {
			setTimeout(() => scrollToElement(location.hash.substring(1), 'auto'), 100);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location?.hash, ids?.length]);

	const handleReload = () => commentsStore.fetch(query, { query, clear: true });

	if (loading === undefined) return;

	if (error) return <ErrorStub {...error} />;

	const handleDeleteItem = (id) => {
		recalcCountCommentsInPost(commentsStore.entity(id)?.post?.id, -1);
		commentsStore.patch(id, { action: 'delete' });
	};

	const handleStashItem = (id) => commentsStore.patch(id, { action: 'hide' });

	const handleRestoreItem = (id) => {
		recalcCountCommentsInPost(commentsStore.entity(id)?.post?.id, 1);
		commentsStore.patch(id, { action: 'restore' });
	};

	const handleVote = (id, vote) => {
		let item = toJS(commentsStore.entity(id));
		if (!item) return;

		if (item?.voted) {
			if (item?.voted === 'y') {
				item.rating.yes--;
			} else {
				item.rating.no--;
			}
		}

		if (item?.voted === vote) {
			item.voted = null;
			vote = '';
		} else {
			item.voted = vote;
			if (!item?.rating) item.rating = { yes: 0, no: 0 };

			if (vote === 'y') {
				item.rating.yes++;
			} else {
				item.rating.no++;
			}
		}

		commentsStore.patch(id, { rating: vote });
	};

	const handleHideItem = (id) => commentsStore.update(id, { hide: true });

	const handleBlock = (author) => {
		const params = { user_id: author?.id, type_id: 5, group_id: post?.group?.id };

		toastPromise(api.post('/v1/group-blacklist', params), {
			loading: 'Отправка...',
			success: `Пользователю "${author?.nick}" запрещено писать комментарии.`,
			error: 'Ошибка',
		});
	};

	const renderItem = (item) => (
		<CommentItem
			{...item}
			postId={id}
			query={query}
			isAuth={isAuth}
			onBlock={handleBlock}
			onHide={handleHideItem}
			edit={[editId, setEdit]}
			onPressVote={handleVote}
			onStash={handleStashItem}
			onDelete={handleDeleteItem}
			onRestore={handleRestoreItem}
			reply={[replyId, setReplyId]}
			me={user?.id === item?.author?.id}
		/>
	);

	let title = loading ? '...' : (totalCount || 'Нет') + ' комментари' + plural(totalCount, 'й', 'я', 'ев');

	return (
		<Stack id="comments">
			<HStack className="justify-between items-center mb-6">
				{title ? <div className="text-lg flex items-start">{title}</div> : ''}
				<Button round size="rect" variant="secondary" onClick={handleReload}>
					<ArrowPathIcon className={classNames('w-5 h-5', loading && 'animate-spin')} />
				</Button>
			</HStack>

			<ItemListNew data={data} renderItem={renderItem} />

			{loaded && !replyId && !editId ? (
				<div className="pt-4 -mx-4 px-2 border-t border-dotted" data-edit={editId}>
					<CommentEditContainer
						postId={id}
						query={query}
						disabled={loading}
						edit={[editId, setEdit]}
						reply={[replyId, setReplyId]}
					/>
				</div>
			) : null}
		</Stack>
	);
};

export default observer(CommentListContainer);

const ErrorStub = ({ message }) => (
	<Stack id="comments">
		<div className="text-center py-2 text-sm text-danger">{message}</div>
	</Stack>
);
