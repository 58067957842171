import { PaperClipIcon, PaperAirplaneIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import useOutsideClick from 'hooks/useOutsideClick';
import useToaster from 'hooks/useToaster';
import { observer } from 'mobx-react';
import { Attachment } from 'modules/Editor';
import DragArea from 'modules/Editor/components/DragArea';
import { useEffect } from 'react';
import { Stack, HStack, Editor, Button, DropdownNew } from 'vgui';

import FileButton from 'modules/PostEditor/components/FileButton';

const RichEditor = (props) => {
	const {
		editor,
		inputRef = null,
		className,
		onChange = () => {},
		placeholder = 'Введите текст',
		disabled = false,
		autofocus = false,
		border = true,
		useEmoji = true,
		configAttachments = [],
		maxCountAttachments = 1,
		onSubmit,
		onEnterPress,
		useDragArea = false,
		dragAreaTarget,
	} = props;

	let timer = null;
	const { toastError } = useToaster();
	const { ref, isComponentVisible, setIsComponentVisible } = useOutsideClick(false);

	useEffect(() => {
		if (autofocus && inputRef?.current) inputRef.current.focus();
	}, [autofocus, inputRef]);

	const toggleDropDown = () => {
		clearTimeout(timer);
		if (!isComponentVisible) {
			timer = setTimeout(() => {
				setIsComponentVisible(false);
				clearTimeout(timer);
			}, 100000);
		}

		setIsComponentVisible((prevState) => !prevState);
	};

	const handlePaste = (data) => handleAddFilesToEditor(data?.files);

	const handleAddFilesToEditor = (files) => {
		if (files?.length + editor.countAttachments > maxCountAttachments) {
			return toastError(
				`Вы не можете прикрепить больше ${maxCountAttachments} ${maxCountAttachments > 1 ? 'файлов' : 'файла'}.`,
			);
		}

		if (files?.length)
			editor.addAttachments(
				files,
				() => onChange(),
				(text) => toastError(text),
			);
	};

	const options = configAttachments.map((item) => ({ ...item, onSelect: handleAddFilesToEditor }));

	if (!editor) return null;

	return (
		<div className="w-full h-full">
			{useDragArea && <DragArea onSelect={handleAddFilesToEditor} dragAreaTarget={dragAreaTarget} />}
			<Stack className="gap-y-3 w-full flex-initial">
				{editor.hasAttachments && (
					<HStack className="w-full gap-2 flex-nowrap overflow-y-hidden overflow-x-auto mx-2">
						{editor.attachments.map((item, key) => (
							<div key={key} className="inline-block">
								<Attachment
									{...item}
									onDelete={() => {
										editor.deleteAttachment(item);
										onChange();
									}}
								/>
							</div>
						))}
					</HStack>
				)}

				<HStack className="gap-1 items-end w-full">
					{configAttachments && (
						<>
							<div>
								<Button
									ref={ref}
									className={classNames('!p-1 mb-1 text-gray-600 hover:text-gray-900 bg-gray-200')}
									variant="success-fill"
									round
									onClick={toggleDropDown}
								>
									<PaperClipIcon className="w-5 h-5" />
								</Button>
								<DropdownNew
									options={options}
									className="left-0 min-w-[10rem]"
									{...{ isComponentVisible, setIsComponentVisible, ref }}
									render={({ item }) => <FileButton key={item?.title} {...item} />}
								/>
							</div>
						</>
					)}

					<Editor
						ref={inputRef}
						border={border}
						useEmoji={useEmoji}
						value={editor?.content}
						onPaste={handlePaste}
						onChange={(e) => {
							editor?.onChange(e);
							if (onChange) onChange(e);
						}}
						onEnterPress={() => onEnterPress(editor?.content, editor?.attachmentsList)}
						className={className}
						placeholder={placeholder}
					/>

					<Button
						className="!p-1 mb-1"
						variant="success"
						round
						onClick={() => onSubmit(editor?.content, editor?.attachmentsList)}
						disabled={editor.isEmpty() || disabled || editor.hasLoadingAttachments}
					>
						<PaperAirplaneIcon className="w-5 h-5" />
					</Button>
				</HStack>
			</Stack>
		</div>
	);
};

export default observer(RichEditor);
