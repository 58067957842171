export default function toQueryString(params) {
	if (params instanceof Object)
		return Object.keys(params)
			.map((key) => {
				if (params[key] === undefined) return null;
				return encodeURIComponent(key) + '=' + encodeURIComponent(params[key]);
			})
			.filter((i) => i && i.length)
			.join('&');

	return params;
}
