import { FilmIcon, PlayCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { SQUARE, VIDEO } from 'constants/Size';
import { plural } from 'helpers';
import { Reach } from 'modules/Analytics';
import { useEffect, useState, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import ReactPlayer from 'react-player/lazy';
import { HStack, Photo, Spinner } from 'vgui';
import { authStore } from 'stores';
import { observer } from 'mobx-react';
import { MIN_DURATION_LOOP_VIDEO } from 'constants/Config';
import { useLocation } from 'react-router-dom';

const platforms = {
	self: 'Ваш город',
	stream: 'Ваш город',
	vk: 'VK Видео',
	ok: 'VK Видео',
	youtube: 'YouTube',
	rutube: 'Rutube',
	vimeo: 'Vimeo',
};

const PlayWrapper = ({ loading }) => (
	<div className="play-wrapper">{loading ? <Spinner size="lg" /> : <PlayCircleIcon className="play" />}</div>
);

const VideoPlayer = observer((props) => {
	const {
		id,
		postId,
		counters,
		loop = true,
		size = '500px',
		autoplay = true,
		onEvent = () => {},
		isPostView = false,
		viewsCounter = true,
		context: { image, platform, url, embed, name, width, height, duration },
	} = props;

	const refSelfPlayerWrap = useRef(null);

	const isOpenPostModal = useLocation()?.search.includes('?w=post');

	const eventVolumeChange = (e) => {
		const player = refSelfPlayerWrap?.current?.getInternalPlayer();
		authStore.setMutedVideo(player?.muted);
		authStore.setVolumeLevel(player?.volume);
	};

	const volumeLevel = authStore.volumeLevel;
	const isMutedVideo = authStore.isMutedVideo;

	const [isShow, setIsShow] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false);

	const [isPlaying, setIsPlaying] = useState(false);
	const [isEnded, setIsEnded] = useState(false);
	const [isWatched, setIsWatched] = useState(false);
	const [isPlayed, setIsPlayed] = useState(false);

	const { ref, inView } = useInView({
		threshold: 0.9,
		rootMargin: '-48px 0px 0px 0px',
		delay: 1000,
	});

	const sq = height >= width;

	useEffect(() => {
		if (!inView && isLoaded) {
			setIsPlaying(false);
		} else if (!isPostView && isOpenPostModal) {
			setIsPlaying(false);
		} else if (inView) {
			if (platform === 'self' && autoplay) {
				setIsShow(true);
			}

			if (isShow && isLoaded && !isEnded && autoplay) {
				setIsPlaying(true);
			}
		}
	}, [inView, isShow, isLoaded, isEnded, platform, autoplay, isPostView, isOpenPostModal]);

	const handlePressPlay = () => setIsShow(true);

	const handleClick = (e) => {
		if (authStore.isMutedVideo) authStore.setMutedVideo(false);
		e.preventDefault();
	};

	const handleReady = () => {
		const player = refSelfPlayerWrap?.current?.getInternalPlayer();
		player?.addEventListener('volumechange', eventVolumeChange, true);
		setIsLoaded(true);
		setIsPlaying(true);
	};

	const handleReadyVK = () => {
		handleReady();
		eventPlay();
	};

	const handleEnd = () => setIsEnded(true);

	const handlePlay = () => {
		setIsEnded(false);
		eventPlay();
	};

	const eventPlay = () => {
		if (!isPlayed) {
			setIsPlayed(true);
			if (id) {
				onEvent('post_video_play', { value: id });
				Reach('video_play', { id: `${postId}_${id}` });
			}
		}
	};

	const handleProgress = (e) => {
		if (!isWatched && (e.played === 1 || e.playedSeconds > 10)) {
			setIsWatched(true);
			onEvent('post_video_watch', { value: id });
		}
	};

	const VKPlayer = (
		<iframe
			muted="0"
			src={embed}
			width="100%"
			title={name}
			height="100%"
			frameBorder="0"
			allowFullScreen=""
			onLoad={handleReadyVK}
			allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
		/>
	);

	const VKPlayerPlug = (
		<div>
			<PlayWrapper platform={platform} />
			<Photo
				alt={name}
				url={image}
				mode="resize"
				size={sq ? SQUARE : VIDEO}
				wrapperClassName="w-full h-full bg-black rounded"
			/>
		</div>
	);

	const RPlayer = (
		<ReactPlayer
			url={url}
			pip={false}
			width="100%"
			height="100%"
			controls={true}
			playing={isPlaying}
			onPlay={handlePlay}
			onEnded={handleEnd}
			volume={volumeLevel}
			muted={isMutedVideo}
			onReady={handleReady}
			onClick={handleClick}
			ref={refSelfPlayerWrap}
			allowFullScreen={false}
			onProgress={handleProgress}
			playIcon={<PlayWrapper platform={platform} />}
			loop={loop && duration <= MIN_DURATION_LOOP_VIDEO}
			config={{ file: { attributes: { playsInline: true } } }}
			className={classNames(`max-h-[${size}]`, sq ? 'aspect-square' : 'aspect-video')}
		/>
	);

	const totalViews = (counters?.attach_play || 'Нет') + ' просмотр' + plural(counters?.attach_play, '', 'а', 'ов');

	return (
		<div className="attachment-video mb-2">
			<div className="bg-black rounded overflow-hidden">
				<div
					ref={ref}
					className={classNames(
						`player`,
						sq ? `aspect-square` : 'aspect-video',
						`platform-${platform.toLowerCase()}`,
					)}
				>
					<div
						onClick={handlePressPlay}
						className={classNames('cover w-full h-full t-0 b-0 absolute z-[2]', isLoaded && 'hidden')}
					>
						<PlayWrapper platform={platform} loading={isShow} />
						<Photo
							alt={name}
							url={image}
							mode="resize"
							size={sq ? SQUARE : VIDEO}
							wrapperClassName="w-full h-full bg-black rounded"
							className={sq ? `aspect-square max-w-[${size}] max-h-[${size}]` : 'aspect-video'}
						/>
					</div>
					{isShow && platform !== 'VK' ? RPlayer : null}
					{isShow && platform === 'VK' && inView ? VKPlayer : null}
					{isShow && platform === 'VK' && !inView ? VKPlayerPlug : null}
				</div>
			</div>
			{viewsCounter && (
				<HStack className="text-xs pt-2 text-gray-500 justify-between">
					<span>{totalViews}</span>
					<span className="flex">
						<FilmIcon className="w-4 h-4 mr-1" />
						{platforms[platform.toLowerCase()] || null}
					</span>
				</HStack>
			)}
		</div>
	);
});

export const AttachmentVideo = ({ items, ...props }) => {
	if (!items) return;

	// todo render few videos
	return <VideoPlayer {...items[0]} {...props} />;
};
