const htmlToText = (html) => {
	return html
		.replace(/<style([\s\S]*?)<\/style>/gi, '')
		.replace(/<script([\s\S]*?)<\/script>/gi, '')
		.replace(/<\/div>/gi, '\n')
		.replace(/<\/li>/gi, '\n')
		.replace(/<li>/gi, '* ')
		.replace(/<\/ul>/gi, '\n')
		.replace(/<\/p>/gi, '\n')
		.replace(/<br\s*[/]?>/gi, '\n')
		.replace(/<[^>]+>/gi, '');
};

export default htmlToText;
