import { makeObservable, action, observable, toJS, values } from 'mobx';

export default class DataStore {
	constructor() {
		this.wipe();
		makeObservable(this);
	}

	// ---------- GENERAL ----------
	wipe = action(() => {
		this.list = observable.map({}); // ids
		this.data = observable.map({}); // entiites
	});

	// ---------- IDS ----------
	ids = (key) => {
		const _key = key.toString();
		if (!this.list.get(_key)?.size || this.list.get(_key)?.size < 1) return [];
		return values(this.list.get(_key));
	};

	setIds = action((key, list) => {
		const items = list ? list.filter((el) => el !== undefined && el !== null) : [];
		this.list.set(key.toString(), new Set(items));
	});

	append = (key, newList) => this.setIds(key, this.ids(key).concat(newList));

	prepend = (key, newList) => this.setIds(key, newList.concat(this.ids(key)));

	remove = (key, id) => {
		this.setIds(
			key,
			this.ids(key).filter((el) => el !== id),
		);
	};

	clear = (key) => this.list.delete(key.toString());

	has = (key) => this.list.has(key.toString());

	sort(key, id) {
		if (this.ids(key)?.length > 1) {
			const a = [id];
			const b = this.ids(key).filter((el) => el !== id);
			this.setIds(key, a.concat(b));
		}
	}

	// ---------- ENTITIES ----------
	entities = (key = '') => {
		const _key = key.toString();
		if (!_key) return values(this.data);
		if (this.ids(_key)?.length < 1) return [];
		return this.ids(_key).map((id) => (id ? this.data.get(id.toString()) : null));
	};

	entity = (id) => {
		if (!id || !this.data.has(id.toString())) return null;
		return toJS(this.data.get(id.toString()));
	};

	setEntity = action((entity) => this.data.set(entity?.id.toString(), entity));

	merge = (items) => items && Object.entries(items).forEach(([_, item]) => this.setEntity(item));

	update = (id, props) => {
		if (!this.entity(id)) return;
		this.setEntity({ ...toJS(this.entity(id)), ...props, ...{ datetime_storage: new Date() } });
	};
}
