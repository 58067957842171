export const NODE_ENV = process.env.REACT_APP_ENV_TYPE;
export const BASE_DOMAIN = 'vashgorod.ru' + NODE_ENV;
export const WEB_URL = 'https://' + BASE_DOMAIN + NODE_ENV;
export const API_URL = 'https://api.vashgorod.ru' + NODE_ENV;
export const CENT_URL = 'wss://vashgorod.ru' + NODE_ENV + '/connection/websocket';
export const METRIX_URL = 'https://api.vashgorod.ru' + NODE_ENV + '/v1/metrix';
export const DEV = process.env.NODE_ENV === 'development';
export const ENV = process.env.NODE_ENV;
export const VERSION = process.env.REACT_APP_RELEASE.replace('%RELEASE%', 'dev');
export const IS_MOBILE = /mobi|iphone|ipod|android|ie|blackberry|fennec/.test(navigator.userAgent.toLowerCase());

export const TITLE_DEFAULT = 'ВашГород' + (IS_MOBILE ? ', мобильная версия' : '');
export const TITLE_SUFFIX = ' - ВашГород' + (IS_MOBILE ? ', мобильная версия' : '');
export const METRIKA_ID = 6334708;
export const MTX_ID = 19374;
export const GA_TRACKING_ID = 'G-8S8E5HH28Y';
export const LIVEINTERNET_ACC = 'parabit/news';

export const ALLOW_MIME = [
	'image/png',
	'image/jpg',
	'image/jpeg',
	'image/webp',
	'video/quicktime',
	'video/mp4',
	'video/mpeg',
];

export const ALLOW_MIME_IMAGE = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/gif'];

export const ALLOW_MIME_VIDEO = '.quicktime, .mp4, .mpeg, .mov, .heic, .webp';

export const MIN_DURATION_LOOP_VIDEO = 10; // seconds

export const SOC_BUTTONS = [];

export const ADFOX_ID = 261181;
export const ADFOX_CITIES = {
	nvkz: [117, 290, 295],
	kem: [],
	nsk: [3],
};
export const ADFOX_PLACES = {
	// desktop
	adfox_151686663623939363: {
		position: 'd1',
		type: '240x400',
		params: { pp: 'mgy', ps: 'cojc', p2: 'fuac' },
		visible: !IS_MOBILE,
	},
	adfox_151728915667051495: {
		position: 'd2',
		type: '240x400',
		params: { pp: 'mha', ps: 'cojc', p2: 'fuac' },
		visible: !IS_MOBILE,
	},

	// mobile
	adfox_168973697323869001: {
		position: 'm0',
		type: '300x250',
		params: { pp: 'dpev', ps: 'cojc', p2: 'ilzf' },
		visible: IS_MOBILE,
	},
	adfox_151731265938773181: {
		position: 'm1',
		type: '330x300',
		params: { pp: 'mgv', ps: 'cojc', p2: 'fuhk' },
		visible: IS_MOBILE,
	},
	adfox_16299638308979469: {
		position: 'm2',
		type: '300x300',
		params: { pp: 'mgw', ps: 'cojc', p2: 'fuhk' },
		visible: IS_MOBILE,
	},

	adfox_151731223791016696: {
		position: 'mi1',
		type: '300x300',
		params: { pp: 'mkh', ps: 'cojc', p2: 'fuhk' },
		visible: IS_MOBILE,
	},
	adfox_165267629953331552: {
		position: 'mi2',
		type: '300x300',
		params: { pp: 'chjc', ps: 'cojc', p2: 'fuhk' },
		visible: IS_MOBILE,
	},
};

export const DEFAULT_LOCATION = {
	id: 1,
	group_id: 309,
	name: 'Москва',
	alias: 'moskva',
	default: true,
	confirm: false,
};

export const GEO_HAS_REDACTION = [117, 36];
export const GROUPS_WITH_ADV = [1731, 9570, 63684, 76277, 76261, 75976, 75726, 75002, 76366, 76093, 76807, 77155];
export const GROUPS_TEST = [
	75802, 75801, 75745, 75725, 75668, 75636, 75324, 75233, 75232, 75231, 75189, 75187, 63684, 59958, 12013, 75728,
	75726, 75713, 75712, 75218, 75002, 74457, 68011, 63026, 39957, 27638, 17317, 9570, 1731,
];
